.ant_form {
  .ant-form-item {
    display: flex;
  }

  .ant-form-item-label {
    display: flex;
    white-space: unset;
    line-height: 15px;
    padding-right: 5px;

    label {
      align-self: center;
    }
  }

  .ant-input[disabled] {
    color: black;
  }

  .ant-select-disabled {
    color: black;
  }
}

