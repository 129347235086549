.container {
  padding-bottom: 40px;
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
  }

  .ant_dev_table {
    :global {
      .ant-table-thead {
        background: #ff4152;

        .ant-table-cell {
          background: #ff4152;
          padding: 8px;
          color: #fff !important;
        }
      }

      .ant-table-summary {
        display: table-header-group;
      }

      .ant-table-column-title {
        color: #fff !important;
      }
      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
      .ant-table-body {
        margin: 0;
      }
    }
  }

  .ant_dev_merge_cell {
    text-align: right !important;
  }
  .markbox {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 0 20px 10px 0;
    .colorBox {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .ant_mark {
        display: inline-block;
        margin-right: 5px;
        width: 30px;
        height: 20px;
      }
    }
  }

  .ant_row_bg_pink {
    background-color: pink !important;
  }

  .ant_row_bg_yellow {
    background-color: yellow !important;
  }

  .btnBox {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    .ant_btn {
      background: #ed1b2e;
      font-family: Arial-BoldMT;
      color: #fff;
      border-color: transparent;
      width: 96px;
      &:hover {
        border-color: transparent;
      }
    }
  }

  .downloadBtn {
    background: #ff4152;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    font-family: ArialMT;
    padding: 4px 8px;
    cursor: pointer;
    color: #fff;
  }

  .blodSpan {
    font-weight: bold;
    font-style: italic;
  }

  .summary_cell {
    padding: 8px;
    border-bottom: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;
    background: #b2b2b2;
  }

  .right {
    text-align: right;
  }

  .center {
    text-align: center;
  }
}

