.container {
 :global {
   .ant-form-explain {
     text-align: left;
   }
 }
 .defaultWidth {
   width: 100%
 }
}

