.container {
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
  }

  .ant_form {
    width: 750px;
  }

  .addBox {
    display: flex;
    padding: 24px 0 20px 0;
    border-top: 1px solid #e6e6e6;
  }

  .ant_btn {
    border: 1px solid #ed1b2e;
    font-family: Arial-BoldMT;
    font-size: 14px;
    color: #ed1b2e;
    letter-spacing: 0;
    margin-right: 16px;
  }

  .groupBox {
    .contentBox {
      box-shadow: -2px -2px 4px 0 rgba(189, 189, 189, 0.15),
        2px 2px 4px 0 rgba(170, 170, 170, 0.15);
      // border: 1px solid #e6e6e6;
      border-radius: 4px;
      margin-bottom: 30px;
      .groupItem {
        padding: 35px 16px 20px;
        &:not(:last-child) {
          border-bottom: 1px solid #e0e0e0;
        }
        display: flex;
        .indexBox {
          font-family: DINCondensed-Bold;
          font-size: 18px;
          color: #000000;
          letter-spacing: 0;
        }
        .content {
          flex: 1;
          margin-left: 8px;
          .questionTop {
            border-radius: 2px 2px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 35px;
            margin-bottom: 14px;
            .typeBox {
              width: 255px;
            }
            .groupAction {
              .actionBtn {
                margin-right: 10px;
                cursor: pointer;
                border-radius: 50%;
                transition: all 0.3s;
                &:hover {
                  background: #ff7152;
                }
              }
            }
          }

          .bottomBox {
            margin-bottom: 20px;
          }

          .answerBox {
            display: flex;
            flex-wrap: wrap;
            .answerItem {
              display: flex;
              margin: 0 8px 18px 0;
              .sortBox {
                font-family: DINCondensed-Bold;
                font-size: 18px;
                color: #333333;
                letter-spacing: 0;
                margin: 3px 8px 0 0;
              }
              .answerInputBox {
                width: 170px;
                :global {
                  .ant-form-item-control {
                    width: 170px !important;
                  }
                }
                .answerInput {
                  width: 170px;
                }
              }
            }

            .addAnswer {
              font-family: ArialMT;
              font-size: 14px;
              color: #ed1b2e;
              letter-spacing: 0;
              border: none;
              box-shadow: none;
            }
          }
        }
      }
    }
  }

  .btnBox {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    .ant_btn_save {
      width: 90px;
    }
  }
}

