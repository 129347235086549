.container {
	.heading {
		font-family: ArialMT;
		font-size: 16px;
		color: #333333;
		letter-spacing: 0;
		border-bottom: 1px solid #e6e6e6;
		height: 51px;
		line-height: 51px;
		margin-bottom: 20px;
	}
	.downloadBox {
		display: flex;
		justify-content: flex-end;
		margin-bottom: 10px;
	}
	.datebox {
		.firstdate {
			margin-right: 10px;
		}
		.datemore {
			height: 5px;
			line-height: 0px;
			display: inline-block;
			font-size: 20px;
			cursor: pointer;
		}
	}
	.ant_dev_table {
		:global {
			.ant-table-body {
				margin: 0;
			}
			.ant-table-thead {
				background: #ff4152;
			}

			.ant-table-column-title {
				color: #fff !important;
			}

			.ant-table-tbody {
				.ant-table-row:nth-child(even) {
					background: #eff0f3;
				}
			}
		}
	}
	.ant_row_bg {
		background: pink !important;
	}
	.ant_row_bg_2 {
		background: lightyellow!important;
	}

	.requirement_status {
		font-family: ArialMT;
		font-size: 12px;
		color: #666;
		letter-spacing: 0;
		display: flex;
		flex-wrap: wrap;
		min-height: 42px;
		border: 1px solid #e8e8e8;
		border-top: none;
		align-items: center;
		.infobox {
			display: flex;
			align-items: center;
			margin: 0 12px 0 8px;
			.circle {
				display: inline-block;
				width: 4px;
				height: 4px;
				border-radius: 50%;
				background: #ed1b2e;
				margin-right: 6px;
			}
		}

		.text {
			margin-left: 20px 0;
		}
		.num {
			color: #000;
			margin-left: 3px;
		}
	}
	:global {
		.ant-form-item {
			display: flex;
			flex-wrap: wrap;
			.ant-form-item-label {
				> label {
					font-family: ArialMT !important;
					font-size: 14px !important;
					color: #666666 !important;
					letter-spacing: 0 !important;
				}
			}
		}
	}
}

