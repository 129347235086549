.container {
  .btnColor {
    background  : #ed1b2e;
    border-color: #ed1b2e;
  }

  .btnText {
    font-family   : Arial-BoldMT;
    font-size     : 16px;
    height        : 40px;
    color         : #ffffff;
    letter-spacing: 0;
    font-weight   : bold;
    width         : 200px;
    text-align    : center;
  }

  .mt10 {
    margin-top: 10px;
  }
}
