.container {
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
  }
  .datebox {
    .firstdate {
      margin-right: 10px;
    }
    .datemore {
      height: 5px;
      line-height: 0px;
      display: inline-block;
      font-size: 20px;
      cursor: pointer;
    }
  }
  .downloadBox {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .ant_form {
    margin-bottom: 16px;
  }
  .spanCss {
    color: #1890ff !important;
    cursor: pointer !important;
  }
  .ant_dev_table {
    :global {
      .ant-table-body {
        margin: 0;
      }
      .ant-table-thead {
        background: #ff4152;
      }

      .ant-table-column-title {
        color: #fff !important;
      }

      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
    }
  }
  :global {
    .ant-form-item {
      display: flex;
      flex-wrap: wrap;
      .ant-form-item-label {
        > label {
          font-family: ArialMT !important;
          font-size: 14px !important;
          color: #666666 !important;
          letter-spacing: 0 !important;
        }
      }
    }
  }
}

:global {
  .ant_popC_training_date {
    .ant-popover-inner {
      max-height: 400px;
      overflow-y: auto;
      width: 140px;
      .ant-popover-title {
        min-width: 140px;
        text-align: center;
      }
      .ant-popover-inner-content {
        text-align: center;
      }
    }
  }
}

