.container {
  .actionWrap {
    margin: 22px 0 27px;
    .ant_suffixicon {
      width: 8px;
      height: 7px;
      margin-top: 3px;
    }
    .actionItem {
      margin: 0 8px;
      // cursor: pointer;
      color: #666;
      margin: 0;
    }
    :global {
      .ant-select-selection {
        border: 1px solid #ed1b2e;
        box-shadow: none !important;
      }
      .ant-select-selection-selected-value {
        color: #ff4152 !important;
      }
    }
  }
  .buttonBox {
    width: 180px;
  }
  .ant_form {
    .ContractDate {
      // display: flex;
      :global {
        .ant-form-item {
          margin-right: 12px;
        }
      }
      .to {
        margin-right: 12px;
        line-height: 40px;
      }
    }
    .bottomWrap {
      margin: 25px 0 40px;
    }
  }
  .serachBox {
    display: flex;
    justify-content: center;
  }
  .table {
    margin-top: 30px;
  }
  .spanCss {
    color: #1890ff !important;
    cursor: pointer !important;
    .text {
      margin-right: 3px;
    }
  }

  .ant_btn {
    background: #ed1b2e;
    font-family: Arial-BoldMT;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    border-color: transparent;
    &:hover {
      border-color: transparent;
    }
  }
  .ant_btn_reset {
    margin-left: 20px;
    font-family: Arial-BoldMT;
    font-size: 14px;
    letter-spacing: 0;
    width: 80px;
    &:hover {
      border-color: #ed1b2e;
      color: #ed1b2e;
    }
  }
  .ant_dev_table {
    :global {
      .ant-table-thead {
        background: #ff4152;
      }

      .ant-table-column-title {
        color: #fff !important;
      }
      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
      .ant-table-body {
        margin: 0;
      }
      .ant-table-row-cell-ellipsis {
        span {
          white-space: normal !important;
        }
      }

    }
  }
}

.ant_dev_dropdown {
  :global {
    .ant-dropdown-menu-item:hover {
      background-color: #ff4141ad!important
    }
  }
}

:global {
  .ant-select-dropdown-menu-item-active {
    background: #ff4152 !important;
    color: #fff !important;
    a {
      color: #fff !important;
    }

    &:hover {
      a {
        color: #fff !important;
      }
    }
  }

  .ant-select-dropdown-menu-item {
    padding: none !important;
    a {
      color: #666;
      display: block !important;
      width: 1000px;
      height: auto;
    }

    &:hover {
      a {
        color: #fff;
      }
    }
  }

  .ant-notification-notice-message {
    font-size: 14px;
    padding-right: 0 !important;
    line-height: 22px;
  }

  .ant-notification-notice {
    padding: 10px;
  }
  .ant-notification-notice-with-icon {
    display: flex;
    align-items: center;
  }

  .ant-style-notification-error {
    .ant-notification-notice-with-icon{
      align-items: stretch !important;
    }
    .ant-notification-notice-message {
      color: red !important;
    }
  }
}

.ant_home_select_drop {
  a {
    color: #666;
    display: block !important;
    width: 1000px;
    height: auto;
    margin: -5px -12px;
    padding: 5px 12px;
  }
}

