.box {
  margin-bottom: 10px;
  box-shadow: -2px -2px 4px 0 rgba(189, 189, 189, 0.15),
    2px 2px 4px 0 rgba(170, 170, 170, 0.15);
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 20px 20px 5px 20px;
  margin-left: 20px;

  .title {
    color: #666;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .ant_dev_dataPicker {
    width: 100%;
  }

  .btn {
    width: 90px;
    background-color: #ff4152;
    color: #fff;
    bottom: 0;
    &:hover,
    &:focus {
      border-color: #ff4152;
    }
  }
}

