.container {
  .header {
    padding-top: 24px;
  }
  .addBtn {
    &:hover,
    &:focus {
      color: rgba(0, 0, 0, 0.65);
      border-color: #d9d9d9;
    }
  }
  .tableBox {
    .headerWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      margin-bottom: 8px;
      .heading {
        font-size: 18px;
        color: #333333;
      }
      .editBtn {
        position: absolute;
        right: 0;
        width: 90px;
        background-color: #ff4152;
        color: #fff;
        bottom: 0;
        &:hover,
        &:focus {
          border-color: #ff4152;
        }
      }

      .disableEditBtn {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 90px;
        color: rgba(0, 0, 0, 0.25);
      }
    }
    .ant_dev_table {
      :global {
        .ant-table-body {
          margin: 0;
        }
        .ant-table-thead {
          background: #ff4152;
          border-radius: 0 !important;
        }

        .ant-table-header {
          background: #ff4152;
        }

        .ant-table-column-title {
          color: #fff !important;
        }

        .ant-table-tbody {
          .ant-table-row:nth-child(even) {
            background: #eff0f3;
          }
        }
      }
    }
  }

  .spanCss {
    color: #1890ff !important;
    cursor: pointer !important;
    display: inline-block;
  }
}
.ant_dev_popcss {
  min-width: 154px;
}

