.container {
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
  }
  .searchBtnBox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px 0 40px;
    .ant_btn {
      width: 90px;
      height: 28px;
      line-height: 28px;
      background: #ed1b2e;
      font-family: Arial-BoldMT;
      font-size: 14px;
      color: #fff;
      letter-spacing: 0;
      border-color: transparent;
      &:hover {
        border-color: transparent;
      }
    }
    .ant_btn_reset {
      margin-left: 20px;
      font-family: Arial-BoldMT;
      font-size: 14px;
      letter-spacing: 0;
      width: 80px;
      &:hover {
        border-color: #ed1b2e;
        color: #ed1b2e;
      }
    }
  }
  .ant_dev_table {
    :global {
      .ant-table-body {
        margin: 0;
      }
      .ant-table-thead {
        background: #ff4152;
        border-radius: 0 !important;
      }

      .ant-table-column-title {
        color: #fff !important;
      }

      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
    }
  }
  :global {
    .ant-form-item {
      display: flex;
      flex-wrap: wrap;
      .ant-form-item-label {
        > label {
          font-family: ArialMT !important;
          font-size: 14px !important;
          color: #666666 !important;
          letter-spacing: 0 !important;
        }
      }
    }
  }
}

