.formRow {
  .headerTitle {
    display: flex;
    font-weight: 700;
    font-size: 600;
    color: #000000;
  }

  .headerLayout {
    display: flex;
    align-items: center;
    width: 650px;
    height: 80px;
  }

  .panelInput {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 23px;
    .label {
      font-weight: 700;
    }
    .required {
      color: #ff4152;
      margin-right: 5px;
    }
  }

  .contentArea {
    width: 100%;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .validateText {
    top: 63px;
    position: absolute !important;
    width: 400px !important;
  }

  .headerToMonth,
  .headerFromMonth {
    display: flex;
    :global {
      .ant-form-item-control-input-content {
        display: flex;
        align-items: center;
      }
      .ant-form-item-explain {
        top: 35px;
        position: absolute !important;
        width: 200px;
      }
      .ant-form-text-after {
        margin-left: 8px;
      }
    }
  }

  .contentLabel {
    font-weight: 700;
  }

  .contentBtnContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 12px;

    .btnColor {
      background: #ed1b2e !important;
      border-color: #ed1b2e !important;
    }

    .btnoutColor {
      background: white;
      border-color: #000;
    }

    .btnoutText {
      font-family: Arial-BoldMT;
      font-size: 14px;
      color: #000000;
      letter-spacing: 0;
      font-weight: bold;
      width: 90px;
      text-align: center;
      margin: 16px;
    }

    .btnText {
      font-family: Arial-BoldMT;
      font-size: 14px;
      color: #ffffff;
      letter-spacing: 0;
      font-weight: bold;
      width: 90px;
      text-align: center;
      margin: 16px;
    }
  }

  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
    .ant-row {
      display: flex;
      align-items: center;
    }
    .ant-input-number-group {
      display: flex;
      // align-items: center;
      .ant-input-number-group-addon {
        margin-left: 2px;
      }
    }
    .ant-input-number {
      width: 120px;
    }
    .ant-form-item-explain-error {
      color: #f5222d;
    }
  }
}

.iconGroup {
  position: absolute;
  top: 10px;
  width: 60px;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .functionIcon {
    font-size: 24px;
  }
  .iconActive {
    color: #3994f2;
  }
}

