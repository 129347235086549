.container {
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
    margin-top: 18px;
    .addSessionBtn {
      background: #da3738;
      border-radius: 10px;
      font-family: Arial-BoldMT;
      color: #fff;
      &:hover {
        border-color: transparent;
      }
      :global {
        .ant-btn {
          .anticon anticon-plus-circle {
            width: 29px;
            height: 29px;
            background: #ffffff;
          }
        }
      }
    }
  }

  .ant_dev_table {
    margin-top: 55px;
    :global {
      .ant-table-body {
        margin: 0;
      }

      .ant-table-thead {
        background: #ff4152;
        border-radius: 0 !important;
      }

      .ant-table-header {
        background: #ff4152;
      }

      .ant-table-column-title {
        color: #fff !important;
      }

      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
    }
  }

  .spanCss {
    color: #ff4152 !important;
    cursor: pointer !important;
    display: inline-block;
  }

  .btnBox {
    // margin: 30px 0;
    margin-top: 4px;
    display: flex;
    justify-content: center;

    .ant_btn {
      background: #ed1b2e;
      font-family: Arial-BoldMT;
      color: #fff;
      border-color: transparent;
      width: 96px;

      &:hover {
        border-color: transparent;
      }
    }
  }
  .btn {
    height: 25px !important;
    background: transparent;
    color: #ff4152;
    border-color: #ff4152;
    text-shadow: transparent;
  }

  .btnContent {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
  .importText {
    font-weight: 400;
    font-size: 16px;
  }
}

.ant_dev_modal {
  .label {
    font-weight: 700;
  }
  .required {
    color: #ff4152;
    margin-left: 5px;
  }
  :global {
    .ant-modal-footer {
      display: flex;
      justify-content: center;
    }
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}

.uploadBox {
  padding: 4px;
  margin-left: 16px;
  min-width: 250px;
  height: 30px;
  border: 1px solid #000000;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
}
.fileName {
  flex: 1;
}
.folder {
  margin-left: 4px;
}

.sessionDateTime {
  :global {
    .ant-row {
      float: right;
      .ant-form-item-control-wrapper {
        width: 100%;
      }
    }
  }
}

// set transfer style
.ant_dev_transfer {
  :global {
    .ant-transfer-list {
      min-width: 385px !important;
    }
  }
}
.ant_btn_warning {
  color: #e89e05;
  border: 1px solid #e89e05;
  &:hover {
    border: 1px solid #e89e05;
    color: #e89e05;
  }
}

.ant_dev_dropdown {
  :global {
    .ant-dropdown-menu {
      .ant-dropdown-menu-item {
        font-size: 14px;
        font-weight: 400;
        color: #ff4152;
        padding: 0 10px;
        height: 30px;
        line-height: 30px;
        &:hover {
          background-color: #ff4141ad !important;
          color: #fff !important;
        }
        &-disabled {
          color: #999;
          &:hover {
            background-color: #fff !important;
            color: #999 !important;
          }
        }
      }
    }
  }
}

.datePickerRow {
  margin-bottom: 0px;
}

.ant_dev_session_error {
  :global {
    .ant-notification-notice-content {
      display: flex !important;
      .ant-notification-notice-with-icon {
        flex-direction: column !important;
        align-items: flex-start !important;
        .ant-notification-notice-message {
          color: red !important;
        }
        .ant-notification-notice-description {
          color: red !important;
        }
      }
    }
  }
}

.ant_dev_radio_transfer {
  display: flex;
  :global {
    .ant-radio {
      align-self: flex-start;
      margin-top: 4px;
    }
  }
}

.ant_dev_modal_alert {
  :global {
    .ant-alert-success {
      display: flex;
      align-items: center;
      margin: auto;
      width: 50%;
    }
  }
}
.transferTips {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 4px;
}

