.container {
  height: 100%;
  width: 100%;
  .header {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: rgba(217, 217, 217, 0.25) solid 2px;
    margin-bottom: 20px;
  }
  .goback {
    border: none;
    box-shadow: none;
    border-color: transparent;
    color: #000000;
    &:hover {
      border: none;
      border-color: transparent;
    }
  }
  .label{
    color: #000000;
    .required{
      margin-left: 5px;
      color: #DA3738;
    }
  }
  :global .ant-form-item-label>label.ant-form-item-no-colon::after{
    display: none;
  }
  .fillBox {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .action {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .durationPopBtn {
    background-color: transparent;
  }

  .apply {
    display: flex;
    justify-content: center;
  }
  .actionWrap {
    margin: 22px 0 27px;
    .ant_suffixicon {
      width: 8px;
      height: 7px;
      margin-top: 3px;
    }
    .actionItem {
      margin: 0 8px;
      // cursor: pointer;
      color: #666;
      margin: 0;
    }
    :global {
      .ant-select-selection {
        box-shadow: none !important;
      }
    }
  }
  .buttonBox {
    width: 180px;
  }
  .ant_form {
    :global {
      .ant-form-item {
        margin-right: 12px;
      }
    }
  }
  .add_import {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  .table {
    margin-top: 16px;
  }
  .ant_btn {
    background: #ed1b2e;
    font-family: Arial-BoldMT;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    border-color: transparent;
    &:hover {
      border-color: transparent;
    }
  }
  .ant_btn_add {
    font-family: Arial-BoldMT;
    font-size: 14px;
    width: 150px;
    color: #ff4152;
    letter-spacing: 0;
    border-color: #ff4152;
    &:hover {
      border-color: #ff4152;
    }
  }
  .ant_btn_link {
    font-family: Arial-BoldMT;
    font-size: 14px;
    width: 200px;
    color: #ff4152;
    margin-right: 16px;
    letter-spacing: 0;
    border-color: #ff4152;
    &:hover {
      border-color: #ff4152;
    }
  }
  .ant_btn_download {
    font-family: Arial-BoldMT;
    font-size: 14px;
    width: 200px;
    color: #ff4152;
    margin-left: 16px;
    letter-spacing: 0;
    border-color: #ff4152;
    &:hover {
      border-color: #ff4152;
    }
  }
  .ant_dev_table {
    :global {
      .ant-table-thead {
        background: #ff4152;
      }

      .ant-table-column-title {
        color: #fff !important;
      }
      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
      .ant-table-body {
        margin: 0;
      }
      .ant-table-fixed-right, .ant-table-fixed-left {
        z-index: 99;
      }
    }
  }
}
.menuItem:hover {
  background-color: #ffeae8;

}
.ant_home_select_drop {
  a {
    color: #666;
    display: block !important;
    width: 1000px;
    height: auto;
    margin: -5px -12px;
    padding: 5px 12px;
  }
}
:global{
  .registration-deleteBtn{
    .ant-popover-message {
      text-align: left !important;
    }
    .ant-popover-message > .anticon {
      top: 38%;
    }
    .ant-popover-arrow {
      left: 73% !important;
    }
    .ant-popover-inner {
      width: 236px !important;
    }
  }
}

.confirmBtn {
  background-color: #DA3738;
  border-radius: 10;
  border-color: #DA3738;
  margin-left: 16px;
  &:hover{
    background-color: #DA3738;
    border-color: #DA3738;
  }
  &:focus{
    background-color: #DA3738;
    border-color: #DA3738;
  }
}
.modalContainer{
  .label{
    color: #000000;
    .required{
      margin-left: 5px;
      color: #DA3738;
    }
  }
  :global .ant-form-item-label>label.ant-form-item-no-colon::after{
    display: none;
  }
}

.modalActionBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.dialogDec {
  margin-bottom: 24;
}

:global{
  .attendance-report-popover{
    .ant-popover-message {
      text-align: left !important;
    }
    .ant-popover-message > .anticon {
      top: 38%;
    }
    .ant-popover-inner {
      background-color: rgba(0, 0, 0, 0.75);
      width: 400px !important;
    }
    .ant-popover-inner-content{
      color: #ffffff;
    }
    .ant-popover-arrow {
      border-right-color: rgba(0, 0, 0, 0.75) !important;
      border-bottom-color: rgba(0, 0, 0, 0.75) !important;
    }
  }
}
.attendanceReportFilters{
  display: flex;
  flex-direction: column;
  .checkBox {
    display: flex;
    flex-direction: column;
    padding-top: 9px;
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .check {
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
    line-height: 32px;
    &:hover{
      background-color: #E6F7FF;
      color: #1890FF;
    }
  }
  :global {
    .ant-checkbox-wrapper {
      margin-left: 0px !important;
    }
  }
  .actionBox {
    padding-left: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
  }
}
