.container {
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
  }
  .topBox {
    display: flex;
    .ant_btn {
      background: #ed1b2e;
      font-family: Arial-BoldMT;
      font-size: 14px;
      color: #fff;
      letter-spacing: 0;
      border-color: transparent;
      margin-right: 16px;
      &:hover {
        border-color: transparent;
      }
    }
    .btnBox {
      margin: 3px 0 0 24px;
    }
  }

  .ant_form {
    min-width: 400px;
  }

  .spancss {
    color: #1890ff !important;
    cursor: pointer !important;
  }

  .ant_dev_table {
    :global {
      .ant-table-body {
        margin: 0;
      }
      .ant-table-thead {
        background: #ff4152;
        border-radius: 0 !important;
      }

      .ant-table-header {
        background: #ff4152;
      }

      .ant-table-column-title {
        color: #fff !important;
      }

      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
    }
  }
}

