.container {
  .spin {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .createButton {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 16px 0 40px;

    .ant_btn {
      width: 246px;
      height: 45px;
      line-height: 45px;
      background: #ed1b2e;
      font-family: Arial-BoldMT;
      font-size: 14px;
      color: #fff;
      letter-spacing: 0;
      border-color: transparent;
      border-radius: 10px;

      &:hover {
        border-color: transparent;
      }
    }
  }
}



