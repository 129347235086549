.container {
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
  }
  .submitBox {
    display: flex;
    justify-content: center;
    .submitBtn {
      background: #ed1b2e;
      border-color: #ed1b2e;
      border-radius: 3px;
      width: 90px;
    }
  }
}

