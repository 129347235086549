.container {
  height        : 100%;
  // overflow-y : auto;
  // overflow-x : hidden;
  min-width     : 1200;
  max-width     : 1470px;
  position      : relative;
  padding-bottom: 50px;

  .showBox {
    width        : 720px;
    height       : 80%;
    min-height   : 500px;
    position     : fixed;
    z-index      : 10;
    top          : 50%;
    left         : 50%;
    transform    : translate(-40%, -45%);
    overflow     : auto;
    background   : #FFFFFF;
    box-shadow   : -1px -1px 4px 0 rgba(0, 0, 0, 0.15), 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    border-radius: 3px;
    padding      : 20px;
  }

  .formCol {
    display        : flex;
    align-items    : center;
    justify-content: space-between;
    margin-bottom  : 10px;
    text-align     : left;
  }

  .editbalBtn {
    background    : #ed1b2e;
    border-color  : #ed1b2e;
    border-radius : 3px;
    width         : 90px;
    font-family   : Arial-BoldMT;
    font-size     : 14px;
    color         : #ffffff;
    letter-spacing: 0;
    font-weight   : bold;
    width         : 90px;
    text-align    : center;
  }

  .submitBtn {
    background    : #eee;
    border-color  : #aaa;
    border-radius : 3px;
    width         : 90px;
    color         : #666;
    font-family   : Arial-BoldMT;
    font-size     : 14px;
    letter-spacing: 0;
    font-weight   : bold;
    width         : 90px;
    text-align    : center;
  }

  .sessionCol {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    margin-bottom  : 10px;

    :global {
      .ant-form-item {
        margin-bottom: 0 !important;
      }

      .ant-form-item-label-left {
        width: 100px !important;
      }
    }
  }

  .selectbox {
    width: 400px;
  }

  .scanBox {
    width          : 70px;
    display        : flex;
    justify-content: space-between;
    align-items    : center;
  }

  .session {
    width  : 85%;
    display: flex;
  }

  .sessionBtn {
    flex           : 1;
    display        : flex;
    justify-content: flex-end;
  }

  .col_dev_left_short {
    :global {
      .ant-form-item-label-left {
        width: 150px !important;
      }
    }
  }

  .col_dev_left {
    :global {
      .ant-form-item-label-left {
        width: 190px !important;
      }
    }
  }

  .ant_dev_two_table {
    :global {
      .ant-table-thead {
        background: #d8d8d8;
      }

      .ant-table-column-title {
        color: #666666 !important;
      }

      .ant-table-body {
        margin: 0;
      }

      .ant-table-small {
        // border: 0px solid transparent;
      }

      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }

      .ant-table-placeholder {
        border-top: 1px solid transparent;
      }
    }
  }

  .ant_dev_table {
    :global {
      .ant-table-thead {
        background: #ff4152;
      }

      .ant-table-column-title {
        color: #fff !important;
      }

      .ant-table-body {
        margin: 0;
      }

      .ant-table-small {
        // border: 1px solid transparent;
      }

      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }

      .ant-table-placeholder {
        border-top: 1px solid transparent;
      }
    }
  }

  .qrContent {
    width          : 500px;
    height         : 600px;
    box-shadow     : 1px 1px 5px gray;
    border-radius  : 3px;
    position       : fixed;
    background     : white;
    z-index        : 100;
    overflow       : hidden;
    top            : 50%;
    left           : 50%;
    transform      : translate(-50%, -50%);
    display        : flex;
    justify-content: center;

    >div:first-child {
      height    : 8px;
      background: #ed1b2e;
    }

    .qrBox {
      display        : flex;
      justify-content: center;
      padding-top    : 10px;

      .qrLeft {
        width          : 500px;
        display        : flex;
        flex-direction : column;
        justify-content: center;
        align-items    : center;

        .qr {
          width           : 400px;
          height          : 400px;
          // background   : red;
          margin          : 0 auto;
          margin-bottom   : 9px;
        }

        .qrText {
          width      : 100%;
          text-align : center;
          font-family: ArialMT;
          font-size  : 14px;
          color      : #333333;
        }

        .qrTimer {
          text-align      : center;
          font-family     : DINCondensed-Bold;
          font-size       : 27px;
          color           : #ed1b2e;
          // margin-bottom: 10px;
          // margin-top   : 10px;
        }

        .qrTime {
          text-align   : center;
          font-family  : ArialMT;
          font-size    : 12px;
          color        : #999999;
          margin-bottom: 18px;
        }

        .qrEchars {
          margin-bottom: 19px;
        }
      }

      .qrRight {

        // width: 80%;
        .qrcol {
          display      : flex;
          align-items  : center;
          font-family  : ArialMT;
          font-size    : 12px;
          color        : #666666;
          height       : 26px;
          margin-bottom: 15px;

          >div:first-child {
            width          : 85px;
            // margin-right: 10px;
          }

          >div:last-child {
            width        : 255px;
            height       : 24px;
            border       : 0.5px solid #979797;
            border-radius: 2px;
            border-radius: 2px;
            line-height  : 24px;
            padding      : 0 10px;
          }
        }
      }
    }
  }

  .btnCol {
    // display        : flex;
    // justify-content: flex-end;
    // align-items    : center;
    margin-bottom     : 15px;
  }

  .btn {
    height      : 25px !important;
    background  : transparent;
    color       : #ff4152;
    border-color: #ff4152;
    text-shadow : transparent;
    margin-right: 15px;
  }

  .btn.active {
    background: #ff4152;
    color     : white;
  }

  .console {
    width       : 20px;
    height      : 19px;
    cursor      : pointer;
    margin-right: 15px;
  }

  .details {
    width : 17px;
    height: 20px;
    cursor: pointer;
  }

  .title {
    height        : 51px;
    border-bottom : 1px solid #e6e6e6;
    line-height   : 51px;
    font-family   : "ArialMT";
    font-size     : 16px;
    color         : #333333;
    letter-spacing: 0;
    margin-bottom : 15px;
  }

  .goCenter {
    display        : "flex";
    justify-content: "center";
    align-items    : "center";
  }

  .fileBtn {
    width        : 31px;
    height       : 24px;
    border-radius: 0 2px 2px 0;
    cursor       : pointer;
  }

  .fileName {
    height      : 24px;
    background  : #ffffff;
    border      : 1px solid #979797;
    flex        : 1;
    margin-left : 10px;
    overflow    : hidden;
    padding-left: 5px;
  }

  .fileUp {
    width        : 100%;
    display      : flex;
    align-items  : center;
    margin-bottom: 40px;
  }

  .btnColor {
    background  : #ed1b2e;
    border-color: #ed1b2e;
  }

  .btnoutColor {
    background  : white;
    border-color: #ed1b2e;
  }

  .btnoutText {
    font-family   : Arial-BoldMT;
    font-size     : 14px;
    color         : #ed1b2e;
    letter-spacing: 0;
    font-weight   : bold;
    width         : 90px;
    text-align    : center;
  }

  .btnText {
    font-family   : Arial-BoldMT;
    font-size     : 14px;
    color         : #ffffff;
    letter-spacing: 0;
    font-weight   : bold;
    width         : 90px;
    text-align    : center;
  }

  .tableTitle {
    width         : 100%;
    height        : "24px";
    font-family   : "ArialMT";
    font-size     : 14px;
    color         : #ffffff;
    letter-spacing: 0;
    background    : "#FF4152";
  }

  .selectFile {
    border: 0;
  }

  .userTable {
    margin-top: 30px;
    border    : 1px solid #bcc1ca;
  }

  :global {
    .ant-upload {
      width: 100%;
    }
  }
}

:global {
  .deleteBtn {
    .ant-popover-message {
      text-align: left !important;
    }

    .ant-popover-inner {
      width: 185px !important;
    }
  }
}
