.container {
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
  }

  .ant_dev_table {
    :global {
      .ant-table-thead {
        background: #ff4152;
      }

      .ant-table-column-title {
        color: #fff !important;
      }
      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
      .ant-table-body {
        margin: 0;
      }
    }
  }

  .btnBox {
    margin: 30px 0;
    display: flex;
    justify-content: center;
    .ant_btn {
      background: #ed1b2e;
      font-family: Arial-BoldMT;
      color: #fff;
      border-color: transparent;
      width: 96px;
      &:hover {
        border-color: transparent;
      }
    }
  }

  .downloadBtn {
    background: #ff4152;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    font-family: ArialMT;
    padding: 4px 8px;
    cursor: pointer;
    color: #fff;
    margin-right: 5px;
  }
}

