.container {
  height: 100%;
  width: 100%;
  .fillBox {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .header {
    height: 48px;
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: rgba(217, 217, 217, 0.25) solid 2px;
    margin-bottom: 20px;
  }
  .goback {
    border: none;
    box-shadow: none;
    border-color: transparent;
    color: #000000;
    &:hover {
      border: none;
      border-color: transparent;
    }
  }
  .action {
    display: flex;
    align-content: center;
    justify-content: center;
  }

  .apply {
    display: flex;
    justify-content: center;
  }
  .actionWrap {
    margin: 22px 0 27px;
    .ant_suffixicon {
      width: 8px;
      height: 7px;
      margin-top: 3px;
    }
    .actionItem {
      margin: 0 8px;
      // cursor: pointer;
      color: #666;
      margin: 0;
    }
    :global {
      .ant-select-selection {
        box-shadow: none !important;
      }
    }
  }
  .buttonBox {
    width: 180px;
  }
  .ant_form {
    :global {
      .ant-form-item {
        margin-right: 12px;
      }
    }
  }
  .add_import {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
  }
  .table {
    margin-top: 16px;
  }
  .ant_btn {
    background: #ed1b2e;
    font-family: Arial-BoldMT;
    font-size: 14px;
    color: #fff;
    letter-spacing: 0;
    border-color: transparent;
    &:hover {
      border-color: transparent;
    }
  }
  .ant_btn_add {
    font-family: Arial-BoldMT;
    font-size: 14px;
    width: 150px;
    color: #ff4152;
    letter-spacing: 0;
    border-color: #ff4152;
    &:hover {
      border-color: #ff4152;
    }
  }
  .ant_btn_link {
    font-family: Arial-BoldMT;
    font-size: 14px;
    width: 200px;
    color: #ff4152;
    margin-right: 16px;
    letter-spacing: 0;
    border-color: #ff4152;
    &:hover {
      border-color: #ff4152;
    }
  }
  .ant_btn_download {
    font-family: Arial-BoldMT;
    font-size: 14px;
    width: 200px;
    color: #ff4152;
    margin-left: 16px;
    letter-spacing: 0;
    border-color: #ff4152;
    &:hover {
      border-color: #ff4152;
    }
  }
  .ant_dev_table {
    .rowConflicted {
      background-color: rgba(237, 27, 46, 0.16) !important;
      color: #000000;
    }
    :global {
      .ant-table-thead {
        background: #ff4152;
      }

      .ant-table-column-title {
        color: #fff !important;
      }
      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
      .ant-table-body {
        margin: 0;
      }
      .ant-table-fixed-right,
      .ant-table-fixed-left {
        z-index: 99;
      }
    }
  }
}
.menuItem:hover {
  background-color: #ffeae8;
  color: #666;
}
.ant_home_select_drop {
  a {
    color: #666;
    display: block !important;
    width: 1000px;
    height: auto;
    margin: -5px -12px;
    padding: 5px 12px;
  }
}
:global {
  .registration-deleteBtn {
    .ant-popover-message {
      text-align: left !important;
    }
    .ant-popover-message > .anticon {
      top: 38%;
    }
    .ant-popover-arrow {
      left: 73% !important;
    }
    .ant-popover-inner {
      width: 236px !important;
    }
  }
}

.confirmBtn {
  background-color: #da3738;
  border-radius: 10;
  border-color: #da3738;
  margin-left: 16px;
  &:hover {
    background-color: #da3738;
    border-color: #da3738;
  }
  &:focus {
    background-color: #da3738;
    border-color: #da3738;
  }
}
.modalContainer {
  .label {
    color: #000000;
    .required {
      margin-left: 5px;
      color: #da3738;
    }
  }
  :global .ant-form-item-label > label.ant-form-item-no-colon::after {
    display: none;
  }
}

.modalActionBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.dialogDec {
  margin-bottom: 24;
}

.ant_dev_session_error {
  :global {
    .ant-notification-notice-content {
      display: flex !important;
      .ant-notification-notice-with-icon {
        flex-direction: column !important;
        align-items: flex-start !important;
      }
    }
  }
}

.duplication {
  background: rgba(237, 27, 46, 0.16) !important;
}

