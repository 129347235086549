.container {
  padding-top: 200px;
  display: flex;
  justify-content: center;
  height: 100%;

  .img {
    width: 77px;
    height: 77px;
  }
  .right {
    margin-left: 30px;
    .num {
      font-family: Georgia;
      font-size: 36px;
      line-height: 36px;
      color: #333333;
    }

    .text {
      font-family: ArialMT;
      font-size: 14px;
      color: #666666;
      line-height: 14px;
      margin: 20px 0;
    }

    .btn {
      background: #ed1b2e;
      border-radius: 3px;
      font-family: ArialMT;
      font-size: 16px;
      height: 28px;
      line-height: 28px;
      width: 115px;
      a {
        text-decoration: none;
        color: #ffffff;
        display: block;
        text-align: center;
      }
    }
  }
}

