.wrapper {
  height: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .loginContainer {
    display: flex;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 120px 0 80px;
    .formWrap {
      width: 439px;
    }

    .mlogo {
      width: 200px;
      height: 62px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .mBGBox {
      width: 100%;
      height: 222px !important;
    }

    .mformWrap {
      width: 375px;
      padding: 0 30px;
    }

    .mformBox {
      width: 100%;
      height: auto;
      min-height: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      padding-bottom: 30px;
    }

    .formBox {
      width: 527px;
      height: 536px;
      background: #ffffff;
      border: 0 solid #979797;
      box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      border-radius: 5px;
      overflow: hidden;
      border-top: 8px solid #ff0000;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 2;
    }

    .logo {
      width: 258px;
      height: 62px;
      margin-top: 50px;
      margin-bottom: 28px;
    }

    .ant-input-affix-wrapper .ant-input:not(:first-child) {
      padding-left: 41px;
    }

    .icon {
      width: 15px;
      height: 18px;
    }

    .rightRedBox {
      width: 165px;
      height: 188px;
      // background: url("/public/wuImg/login/boxright.png");
    }

    .bgBox {
      flex: 1;
      height: 188px;
    }

    .left {
      flex: 1;
      display: flex;
      align-items: center;
      position: relative;
      overflow: hidden;
    }

    .title {
      position: absolute;
      left: 6px;
      bottom: 0;
      width: 100%;
      font-family: Arial-BoldMT;
      font-size: 38px;
      color: #ffffff;
      letter-spacing: 0.01px;
    }

    .btn {
      width: 100%;
      background: #ff0000;
      border-radius: 21px;
      height: 42px;
      font-family: Arial-BoldMT;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      margin-top: 35px;
    }
    
    .adbtn {
      margin-top: 11px !important;
      margin-bottom: 60px;
    }

    .onlyadmargin {
      // margin-top: 120px;
      // margin-bottom: 120px;
    }

    .mt0 {
      margin-bottom: 0;
    }

    .ssobtn {
      width: 100%;
      background: #ff0000;
      border-radius: 21px;
      height: 42px;
      font-family: Arial-BoldMT;
      font-size: 16px;
      color: #ffffff;
      letter-spacing: 0;
      margin-top: 25px;
    }

    .fp {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .mBack {
      width: 100%;
      height: 24px;
      position: relative;
    }

    .back {
      width: 15px;
      height: 24px;
      position: absolute;
      top: 16px;
      left: 19px;
      cursor: pointer;
    }
  }

  .nologinPadding{
    padding: 120px 0 0;
  }
  .noPadding{
    padding: 0 0 30px;
  }

  .mSpin {
    :global {
      .ant-spin-nested-loading {
        width: 100%;
        height: auto;
        min-height: 100%;
      }
    }
  }

  .padSpin {
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .formBox {
      margin-top: 150px;
    }

    .mBGBox {
      width: 100%;
      height: 380px !important;
      position: absolute;
      top: 0;
    }

    .padBg {
      position: absolute;
      width: 100%;
      height: 200px;
      background: #ff0000;
      top: 50%;
    }
  }

  .tcBox {
    padding-bottom: 20px;
    text-align: center;
  }
}
.noPadding{
  padding: 0 0 30px;
}


