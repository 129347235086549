.container {
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
  }
  .contentBox {
    max-width: 978px;
    .uploadBox {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .uploadtitle {
        margin-right: 10px;
        font-family: ArialMT;
        min-width: 65px;
        font-size: 14px;
        color: #666;
        letter-spacing: 0;
      }

      .showpath {
        width: 624px;
        min-width: 130px;
        height: 24px;
        padding-left: 7px;
        border: 1px solid #979797;
      }

      .uploadIconBox {
        height: 24px;
        width: 31px;
        background: #ff4152;
        border-radius: 0 2px 2px 0;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        .uploadIcon {
          height: 13px;
          width: 16px;
        }
      }
    }
    .downloadBox {
      font-family: Arial-BoldMT;
      letter-spacing: 0;
      .downloadBtn {
        margin-left: 15px;
        height: 25px;
        line-height: 25px;
        font-family: ArialMT;
        font-size: 14px;
        color: #ff4152;
        letter-spacing: 0;
        border-color: #ff4152 !important;
        &:hover {
          color: #ff4152;
          border-color: #ff4152 !important;
        }
      }
    }
    .uploadAndCheckBtnBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      font-family: Arial-BoldMT;
      letter-spacing: 0;
      .uploadBtn {
        width: 152px;
        height: 28px;
        line-height: 28px;
      }
      .uploadBtnActive {
        width: 152px;
        height: 28px;
        line-height: 28px;
        background: #ed1b2e;
        color: #fff !important;
        border-color: transparent !important;
        &:hover {
          color: #fff !important;
          border-color: transparent !important;
        }
      }
    }

    .importBox {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      font-family: Arial-BoldMT;
      letter-spacing: 0;
      .importBtn {
        width: 152px;
        height: 28px;
        line-height: 28px;
      }
      .importBtnActive {
        width: 152px;
        height: 28px;
        line-height: 28px;
        background: #ed1b2e;
        color: #fff !important;
        border-color: transparent !important;
        &:hover {
          color: #fff !important;
          border-color: transparent !important;
        }
      }
    }
    .tabelbox {
      margin-top: 30px;
      .ant_dev_table {
        :global {
          .ant-table-body {
            margin: 0;
          }
          .ant-table-thead {
            background: #ff4152;
            border-radius: 0 !important;
          }

          .ant-table-header {
            background: #ff4152;
          }

          .ant-table-column-title {
            color: #fff !important;
          }

          .ant-table-tbody {
            .ant-table-row:nth-child(even) {
              background: #eff0f3;
            }
          }
        }
      }
    }
  }
}

