.box {
  color: #666;
  padding: 0 30px;
  .title {
    text-decoration: underline;
    color: #1890ff;
    margin: 0 3px;
    &:hover {
      color: #ff4d4f;
      text-decoration: underline;
    }
  }
}



