.editBtn {
  color: #1890ff;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

.disableLink {
  color: #5B5B5B  !important;
  cursor:default;
  display: inline-block;
}

.redHighlight {
  color: red;
}

