.container {
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 51px;
    line-height: 51px;
    margin-bottom: 20px;
  }

  .ant_form {
    width: 750px;
  }

  .addBox {
    display: flex;
    padding: 24px 0 10px 0;
    justify-content: flex-end;
    border-top: 1px solid #e6e6e6;
  }

  .ant_btn {
    border: 1px solid #ed1b2e;
    font-family: Arial-BoldMT;
    font-size: 14px;
    color: #ed1b2e;
    letter-spacing: 0;
    margin-right: 16px;
  }

  .groupBox {
    background: #ffffff;
    .groupItem {
      margin-bottom: 10px;
      box-shadow: -2px -2px 4px 0 rgba(189, 189, 189, 0.15),
        2px 2px 4px 0 rgba(170, 170, 170, 0.15);
      transition: all 3s;
      .groupTop {
        background: #ff4152;
        border-radius: 2px 2px 0 0;
        display: flex;
        justify-content: space-between;
        height: 35px;
        :global {
          .ant-form-item-control {
            line-height: 35px !important;
          }
        }
        .mandatoryBox {
          margin-left: 16px;
          width: 130px;
          :global {
            .ant-select-selection--single {
              height: 26px;
              background: #ff4152;
              font-family: ArialMT;
              color: #ffffff;
              border: 1px solid #ffffff;
              border-radius: 2px;
            }
            .ant-select-arrow {
              font-family: ArialMT;
              color: #ffffff;
            }
            .ant-select-selection__rendered {
              line-height: 25px;
            }
          }
        }

        .grouptitle {
          font-family: PingFangSC-Semibold;
          font-size: 16px;
          color: #ffffff;
          letter-spacing: 0;
          margin-top: 5px;
        }
        .actionBox {
          width: 155px;
          .groupAction {
            margin: 5px 5px 0 0;
            text-align: right;
            .actionBtn {
              margin-right: 10px;
              cursor: pointer;
              border-radius: 50%;
              &:hover {
                background: #ff7152;
              }
            }
          }
        }
      }

      .questionBox {
        margin: 0 20px;
        .questionItem {
          display: flex;
          padding: 25px 0;
          &:not(:last-child) {
            border-bottom: 1px solid #e0e0e0;
          }
          .questionIndex {
            font-family: DINCondensed-Bold;
            font-size: 18px;
            color: #000000;
            letter-spacing: 0;
          }
          .questionContent {
            padding: 3px 0 0 12px;

            .questionTitle {
              font-family: ArialMT;
              font-size: 14px;
              color: #666666;
              letter-spacing: 0;
              text-align: justify;
              margin-bottom: 20px;
            }
            .questionTypeBox {
              .answerBox {
                display: flex;
                flex-wrap: wrap;
                .answerItem {
                  margin-right: 26px;
                  display: flex;
                  align-items: center;
                  .ant_com_box {
                    margin-top: -2px;
                  }
                  .nameBox {
                    font-family: DINCondensed-Bold;
                    font-size: 18px;
                    color: #999999;
                    letter-spacing: 0;
                  }
                  .valueBox {
                    font-family: DINCondensed-Bold;
                    font-size: 18px;
                    color: #999999;
                    letter-spacing: 0;
                  }
                }
                :global {
                  .ant-radio-wrapper {
                    margin-right: 8px;
                  }
                  .ant-checkbox-wrapper {
                    margin-right: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }

    .btnBox {
      margin: 30px 0;
      display: flex;
      justify-content: center;
      .ant_btn_save {
        width: 90px;
      }
    }
  }
}

