.container {
  .heading {
    font-family   : ArialMT;
    font-size     : 16px;
    color         : #333333;
    letter-spacing: 0;
    border-bottom : 1px solid #e6e6e6;
    height        : 51px;
    line-height   : 51px;
    margin-bottom : 20px;
  }

  .ant_dev_table {
    :global {
      .ant-table-body {
        margin: 0;
      }

      .ant-table-thead {
        background   : #ff4152;
        border-radius: 0 !important;
      }

      .ant-table-header {
        background: #ff4152;
      }

      .ant-table-column-title {
        color: #fff !important;
      }

      .ant-table-tbody {
        .ant-table-row:nth-child(even) {
          background: #eff0f3;
        }
      }
    }
  }

  .spanCss {
    color  : #1890ff !important;
    cursor : pointer !important;
    display: inline-block;
  }

  .btnBox {
    // margin: 30px 0;
    margin-top     : 4px;
    display        : flex;
    // justify-content: center;

    .ant_btn {
      background  : #ed1b2e;
      font-family : Arial-BoldMT;
      color       : #fff;
      border-color: transparent;
      width       : 96px;

      &:hover {
        border-color: transparent;
      }
    }
  }
  .btn {
    height      : 25px !important;
    background  : transparent;
    color       : #ff4152;
    border-color: #ff4152;
    text-shadow : transparent;
  }

  .btnContent{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

}
