.container {
  min-width: 1200px;
  .heading {
    font-family: ArialMT;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    .gobtn {
      margin-left: 30px;
    }
  }
  .inputs {
    width: 205px;
  }

  .addBtn {
    font-family: ArialMT;
    font-size: 14px;
    color: #ff4152;
    letter-spacing: 0;
    border: 1px solid #ff4152;
    :global {
      .ant-btn:hover,
      .ant-btn:focus {
        color: #ff4152;
        border-color: #ff4152;
      }
    }
  }

  .ant_form {
    font-family: ArialMT !important;
    font-size: 14px !important;
    color: #666666 !important;
    letter-spacing: 0 !important;
    .wrapper {
      .title {
        span {
          display: inline-block;
          padding-left: 10px;
          margin-bottom: 17px;
          border-left: 4px solid #ed1b2e;
          font-family: ArialMT;
          font-size: 16px;
          line-height: 16px;
          color: #333333;
          letter-spacing: 0;
        }
      }
      .ant_input {
        width: 216px;
      }
      .ant_single {
        margin-bottom: 24px;
      }
      .ant_col_horizon {
        display: flex;
        flex-wrap: wrap;
        > span {
          padding-top: 8px;
          margin-right: 25px;
        }
      }
    }
    .submitBox {
      display: flex;
      justify-content: center;
      .editbalBtn {
        background: #ed1b2e;
        border-color: #ed1b2e;
        border-radius: 3px;
        width: 90px;
      }

      .submitBtn {
        background: #eee;
        border-color: #aaa;
        border-radius: 3px;
        width: 90px;
        color: #666;
      }

      .cancelBtn {
        margin-left: 20px;
        background: #ed1b2e;
        border-color: #ed1b2e;
        border-radius: 3px;
        width: 90px;
      }
    }

    :global {
      .ant-form-item {
        display: flex;
        flex-wrap: wrap;
        .ant-form-item-label {
          > label {
            font-family: ArialMT !important;
            font-size: 14px !important;
            color: #666666;
            letter-spacing: 0 !important;
          }
        }
      }
    }
  }
}

.LIAMWrapBox {
  .totals {
    lable {
      font-family: ArialMT;
      color: #333333 !important;
    }
  }
  .titleBox {
    display: flex;
    flex-wrap: wrap;
    .headings {
      min-width: 300px;
      font-family: ArialMT;
      color: #333333 !important;
      letter-spacing: 0;
    }
    :global {
      .ant-form-item-label {
        > label {
          font-family: ArialMT !important;
          font-size: 14px !important;
          color: #333 !important;
          letter-spacing: 0 !important;
        }
      }
    }
  }
  .structuredBox {
    // width: 600px;
    display: flex;
    .titles {
      font-family: ArialMT;
      color: #666666;
      letter-spacing: 0;
      width: 105px;
    }
    .minform {
      margin: 0 25px;
    }
  }
  .mtatitles {
    font-family: ArialMT;
    color: #666666;
    letter-spacing: 0;
    width: 185px;
  }
}

