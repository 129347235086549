.container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: absolute;
  .qrCodeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    .qrCodeHeader {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 16px;
      font-weight: bold;
      flex-direction: column;
    }
    .qrCodeFooter {
      overflow-y: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 400px;
      font-size: 16px;
      flex-direction: column;
      .scanInfo {
        width: 400px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        .infoItem {
          margin-left: 8px;
          margin-right: 8px;
          display: flex;
        }
        .infoItemColor {
          color: #1890ff;
          margin-left: 8px;
          margin-right: 8px;
        }
        .attendeeColor {
          color: #1890ff;
        }
      }
    }
    .boldFont {
      font-weight: bold;
    }
  }
}

