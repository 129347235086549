.container {
  margin: 20px 0;
  .title {
    font-weight: bold;
    font-weight: 16px;
  }
  .select {
    margin: 0 10px;
    min-width: 60px;
  }
}
