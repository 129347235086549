.container {
	margin-bottom: 30px;
	.headerbox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		background: #ff4152;
		height: 24px;
		.title {
			font-family: Arial-BoldMT;
			font-size: 15px;
			color: #ffffff;
			letter-spacing: 0;
			margin-left: 14px;
		}
		.status {
			font-family: ArialMT;
			font-size: 14px;
			color: #ffffff;
			letter-spacing: 0;
			margin-right: 16px;
		}
	}
	.ant_dev_table {
		:global {
			.ant-table-body {
				margin: 0;
			}
			.ant-table-thead {
				background: #d8d8d8;
				font-family: ArialMT;
				font-size: 14px;
				color: #666;
				letter-spacing: 0;
				border-radius: 0 !important;
			}

			.ant-table-column-title {
				font-family: ArialMT;
				font-size: 14px;
				color: #666;
				letter-spacing: 0;
				border-radius: 0 !important;
			}

			.ant-table-tbody {
				.ant-table-row:nth-child(even) {
					background: #eff0f3;
				}
			}

			.ant-table-small {
				border-radius: 0;
			}
		}
  }
  .ant_row_bg {
		background: pink!important;
	}
	.ant_row_bg_2 {
		background: lightyellow!important;
	}
	:global {
		.ant-form-item {
			display: flex;
			flex-wrap: wrap;
			.ant-form-item-label {
				> label {
					font-family: ArialMT !important;
					font-size: 14px !important;
					color: #666666 !important;
					letter-spacing: 0 !important;
				}
			}
		}
	}
}

