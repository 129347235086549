.container {
  .title {
    margin-bottom: 8px;
    span {
      display: inline-block;
      padding-left: 10px;
      margin: 6px 0 16px;
      border-left: 4px solid #ed1b2e;
      font-family: ArialMT;
      font-size: 16px;
      line-height: 16px;
      color: #333333;
      letter-spacing: 0;
    }
  }
}

